import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";


export default ({ heading, content, image, button }) => {

  const data = useStaticQuery(graphql`
    query CTAQuery {
      cta: wordpressAcfOptions {
        options {
          cta {
            heading
            content
            button {
              title
              url
              target
            }
            image {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth:500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const defaultCTA = data.cta.options.cta;

  heading = heading || defaultCTA.heading;
  content = content || defaultCTA.content;
  button = button || defaultCTA.button;
  image = image || defaultCTA.image;


  return (
    <section className="md:my-32 my-20">
      <div className="o-wrapper">
        <div className="flex flex-wrap">

          <div className="md:w-1/2 w-full order-1 md:order-0 bg-blue-light flex flex-col items-center justify-center text-center md:py-32 py-20  px-6">
            <div className="max-w-xs">
              <h3 className="u-h2">{ heading }</h3>
              <p className="mb-6 mt-5" dangerouslySetInnerHTML={{ __html: content }} />
              <a href={button.url} target={button.target} className="inline-block c-btn bg-black text-white hover:text-black hover:bg-white">{button.title}</a>
            </div>
          </div>

          <div className="md:w-1/2 w-full flex flex-col justify-end">
            <div className="bg-tertiary flex-auto img--cover flex ">
              {image && image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.fluid && <Image fluid={image.localFile.childImageSharp.fluid} className="flex-auto " />}
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}