import React, { Component } from 'react';

export default class Accordion extends Component {
  render() {
    return (
      <div className="accordion">
        {this.props.items.map((item, i) =>
          <AccordionItem
            key={item.heading+i}
            question={item.heading}
            answer={item.content}
          />
        )}
      </div>
    );
  }
}

class AccordionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(() => ({...this.state, isOpen: !this.state.isOpen }))
  }


  render() {
    return (
      <div className="accordion-item border-b border-grey-mid">

        {/* eslint-disable-next-line */}
        <header onClick={this.handleClick} className="flex justify-between items-center py-4 md:py-5 cursor-pointer">
          <div className="text-20px font-medium">
            {this.props.question}
          </div>
          <img
            src={require('../images/circle-arrow.svg')}
            className={`ml-3 transform scale-75 sm:scale-90 md:scale-100 ${this.state.isOpen ? '' : 'rotate-180'}`}
            alt="Arrow"
            style={{flex:'0 0 auto'}}
          />
        </header>

        <div className={`overflow-hidden transition-all duration-500 ease-in-out ${this.state.isOpen ? 'max-h-700' : 'max-h-0'}`}>
          <main className="mb-10 md:pr-5 post-content" dangerouslySetInnerHTML={{ __html:this.props.answer }}/>
        </div>

      </div>
    );
  }
}