import React, { Component } from "react"
import { graphql } from "gatsby"
import Scrollspy from "react-scrollspy"
import styled from 'styled-components'
import slugify from "slugify"
import Fuse from "fuse.js"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Accordion from '../components/accordion'
import CTA from "../components/cta"
const Form = styled.form`
  height:55px;
  @media screen and (min-width:768px) {
    height:78px;
  }

  input::placeholder {
    color:#000;
    opacity:.5;
  }
`

const NavItem = styled.div`
  opacity:.5;

  &.is-current {
    opacity:1;
  }

  a:hover {
    text-decoration: underline;
  }
`

const Category = styled.section`
  .accordion-item header {
    @media(min-width:640px) {
      padding-top:2rem;
      padding-bottom:2rem;
    }
  }
`
function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}

class FAQS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      loaded: false
    };

    this.faqs = flatten(this.props.data.page.acf.categories
    .map(({ name, faqs }) => {
      return faqs.map(f => {
        return {...f, category: name};
      })
    }));

    this.categories = this.props.data.page.acf.categories;

    this.fuse = new Fuse(this.faqs, {
      shouldSort: true,
      keys: [ 'heading' ]
    });

    this.handleChange = this.handleChange.bind(this);
    this.getResults = this.getResults.bind(this);
  }

  componentDidMount() {
    setTimeout(()=>{
      this.setState(({ ...this.state, loaded: true}));
    },700);
  }

  async handleChange(event) {
    await this.setState({filter: event.target.value});
  }


  getResults(filter) {
    if (!filter) return this.categories;

    let cats = this.categories.map(c => {
      return { name: c.name, faqs: [] }
    });

    const faqs = this.fuse.search(filter);

    cats = cats
    .map(c => {
      return {
        name: c.name,
        faqs: faqs.filter(f => f.category === c.name)
      }
    }).filter(c => c.faqs.length > 0);

    return cats;
  }

  render() {
    const categories = this.getResults(this.state.filter);

    const { acf, yoast_meta } = this.props.data.page;
    const { hero, search_placeholder, cta } = acf;
    return (
      <Layout>
        <SEO yoast={yoast_meta} />

        <Hero
          heading={hero.hero.heading}
          content={hero.hero.content}
          image={hero.hero.image.localFile.childImageSharp.fluid}
          buttons={hero.hero.buttons}
          reverse={true}
          loaded={this.state.loaded}
          bg={hero.hero.background_colour || 'tan'}
        />

        {/* Search Box */}
        <section className="search mb-4 md:mt-16 md:mb-6">
          <div className="o-wrapper">
            <div className="breakout-wrapper-until-md">
              <div className="bg-tertiary p-6 md:p-8 md:px-12 w-full" style={{maxWidth:'1148px'}}>
                <Form onSubmit={(e)=>{e.preventDefault(); return false;}} className="flex items-center bg-white md:px-3">
                  <input
                    placeholder={search_placeholder}
                    type="text"
                    className="text-20px flex-auto outline-none px-4"
                    style={{height:'47px'}}
                    value={this.state.filter}
                    onChange={this.handleChange}
                  />
                  <button type="submit" className="hover:opacity-75 transition-opacity duration-150 ease-in-out">
                    <img src={require('../images/icons/search.svg')} alt="Search" />
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-16">
          <div className="o-wrapper">
            <div className="flex xl:-ml-24 -ml-20 items-start">
              {categories.length > 0 &&
              <>
                <aside className="hidden md:block xl:pl-24 pl-20 pt-12 sticky top-header-height" style={{flex: '1 1 auto'}}>
                  <nav>
                    <Scrollspy items={categories.map(c => 'section-'+slugify(c.name.toLowerCase()))}  currentClassName="is-current">
                      {categories.map(category =>
                        <NavItem key={`category-sticknav-${category.name}`}>
                          <a href={`#${'section-'+slugify(category.name.toLowerCase())}`} className="inline-block mb-3">
                            {category.name}
                          </a>
                        </NavItem>
                      )}
                    </Scrollspy>
                  </nav>
                </aside>

                <main className="flex-auto xl:pl-24 pl-20 md:pt-4 w-full">
                  {categories.map(category =>
                    <Category id={'section-'+slugify(category.name.toLowerCase())} style={{maxWidth:'952px'}} key={`faq-category-${category.name}`}>
                      <Accordion items={category.faqs} />
                    </Category>
                  )}
                </main>
                </>
              }

              {categories.length === 0 &&
                <div className="pt-12 mx-auto w-full o-wrapper text-lg" style={{ maxWidth:'1148px'}}>No results found</div>
              }
            </div>
          </div>
        </section>

        <CTA
          heading={cta.heading}
          content={cta.content}
          image={cta.image}
          button={cta.button}
        />

      </Layout>
    );
  }
}

export default FAQS;



export const query = graphql`
  query Faqs($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      ...Yoast
      acf {
        hero {
          ... Hero
        }

        search_placeholder

        categories {
          name
          faqs {
            heading: question
            content: answer
          }
        }

        cta {
          heading
          content
          button {
            title
            url
            target
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth:600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;